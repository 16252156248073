import config from '@/lib/config'
import { ApiCore } from '@/lib/mdip/api-core'
import {
  HttpHandlerProps,
  Path,
  PathMethod,
  ApiResponseType,
} from '@/lib/mdip/types'
import { signIn } from 'next-auth/react'

const API_BASE_URL = config.NEXT_PUBLIC_API_URL.endsWith('/')
  ? config.NEXT_PUBLIC_API_URL.slice(0, -1)
  : config.NEXT_PUBLIC_API_URL

export const ApiClient = async <P extends Path, M extends PathMethod<P>>({
  url,
  method,
  payload,
  options,
  params,
}: HttpHandlerProps<P, M>): Promise<ApiResponseType<P, M>> => {
  const response = await ApiCore({
    url: (API_BASE_URL + url) as P,
    method: method.toUpperCase() as M,
    payload,
    params,
    options,
  })

  if (
    !response.ok ||
    response.headers.get('content-type')?.includes('text/html')
  )
    return handleErrorResponse(response)

  return handleSuccessResponse(response, method)
}

const handleSuccessResponse = async <P extends Path, M extends PathMethod<P>>(
  response: Response,
  method: string,
) => {
  if (method.toLowerCase() === 'delete') {
    // Discuss with BE to return a valid response instead of No Content response
    response.json = async () => ({
      message: 'deleted successfully',
      error: false,
    })
  }
  return response.json() as Promise<ApiResponseType<P, M>>
}

const handleErrorResponse = async (response: Response) => {
  if (response.status === 403) {
    await signIn()
  }
  const error = await response.text()
  throw new Error(error)
}
