import { TOmitReadonlyProperties, ValueOf } from '@/types/generics'
import { components, paths } from 'sdk/openapitypescript/mdip-api'
export type Path = keyof paths
export type PathMethod<T extends Path> = Extract<keyof paths[T], string>

export type ApiRequestParams<
  P extends Path,
  M extends PathMethod<P>,
> = paths[P][M] extends {
  parameters: any
}
  ? paths[P][M]['parameters']
  : undefined

export type ApiRequestPayload<
  P extends Path,
  M extends PathMethod<P>,
  S extends
    | 'application/json'
    | 'application/x-www-form-urlencoded'
    | 'multipart/form-data' = 'application/json',
> = paths[P][M] extends {
  requestBody?: { content: { [K in S]: infer T } }
}
  ? TOmitReadonlyProperties<T>
  : undefined

type ResponseCodesValue<
  P extends Path,
  M extends PathMethod<P>,
> = paths[P][M] extends {
  responses: any
}
  ? ValueOf<paths[P][M]['responses']>
  : never

export type ApiResponseType<
  P extends Path,
  M extends PathMethod<P>,
> = paths[P][M] extends { responses: any }
  ? ResponseCodesValue<P, M> extends {
      content: { 'application/json': any }
    }
    ? ResponseCodesValue<P, M>['content']['application/json']
    : undefined
  : undefined

export interface MdipInternalFetchOptions {
  contentType?: 'auto'
  [key: string]: any
}

export type HttpHandlerProps<P extends Path, M extends PathMethod<P>> = {
  url: P
  method: M
  payload?: ApiRequestPayload<P, M>
  options?: MdipInternalFetchOptions
  params?: ApiRequestParams<P, M>
}

export const resolveUrlParams = <P extends Path, M extends PathMethod<P>>(
  params: ApiRequestParams<P, M>,
  url: P,
) => {
  let resolvedUrl = url
  if (params) {
    for (const key in params.path) {
      resolvedUrl = resolvedUrl.replace(`{${key}}`, params.path[key]) as P
    }
    if (params.query) {
      resolvedUrl =
        `${resolvedUrl}?${new URLSearchParams(params.query).toString()}` as P
    }
  }
  return resolvedUrl
}
