import {
  MdipInternalFetchOptions,
  PathMethod,
  resolveUrlParams,
  Path,
  HttpHandlerProps,
} from '@/lib/mdip/types'
import { getServerSession } from '@/lib/auth/getServerSession'

/**
 * Determines the appropriate Content-Type header for the request
 * @param options - Optional fetch options containing contentType configuration
 * @returns The Content-Type string or undefined if set to 'auto'
 */
const determineContentType = (options?: MdipInternalFetchOptions) => {
  // If contentType is auto, let the browser decide
  if (options?.contentType === 'auto') return
  if (options?.contentType != null) return options.contentType
  return 'application/json'
}

/**
 * Processes and formats the request body based on the request configuration
 * @param props - Request properties including URL, method, payload and options
 * @returns Formatted request body ready for fetch
 */
const processRequestBody = async <P extends Path, M extends PathMethod<P>>({
  url,
  method,
  payload,
  options,
}: HttpHandlerProps<P, M>) => {
  if (url.includes('http://localhost:3001') || url.includes('lambda-url')) {
    const mockerMiddleware = (await import('./mockerMiddleware.js')).default
    payload = mockerMiddleware(payload, method, url)
  }
  if (options && options?.contentType === 'auto') return payload
  return payload && JSON.stringify(payload)
}

/**
 * Generates headers for the request including authentication and content type
 * @param props - Object containing session token and content type
 * @returns Headers object ready for fetch
 */
const generateRequestHeaders = ({
  sessionToken,
  contentType,
}: {
  sessionToken?: string
  contentType: ReturnType<typeof determineContentType>
}) => {
  const headers: Record<string, string> = {}

  if (sessionToken) {
    headers['authorization'] = `Bearer ${sessionToken}`
  }

  if (contentType != null) {
    headers['Content-Type'] = contentType
  }
  return headers
}

/**
 * Resolves URL parameters in the request URL
 * @param props - Object containing URL and parameters
 * @returns Resolved URL with parameters interpolated
 */
const resolveRequestUrl = <P extends Path, M extends PathMethod<P>>({
  url,
  params,
}: Pick<HttpHandlerProps<P, M>, 'url' | 'params'>) => {
  if (!params) return url
  return resolveUrlParams(params, url)
}

/**
 * Resolves URL parameters in the request options
 * @param props - Object containing parameters and options
 * @returns Options with resolved URL parameters
 */
const resolveRequestOptions = <P extends Path, M extends PathMethod<P>>({
  params,
  options,
}: Pick<HttpHandlerProps<P, M>, 'params' | 'options'>) => {
  if (!options?.headers?.ProxiedURL) return options
  options.headers.ProxiedURL = resolveUrlParams(
    params,
    options.headers.ProxiedURL,
  )
  return options
}

/**
 * Internal HTTP client for making authenticated API requests
 * Handles authentication, content type, URL parameter resolution, and request body processing
 * @param props - Request configuration including URL, method, payload, parameters and options
 * @returns Promise resolving to the fetch Response
 */
export const ApiCore = async <P extends Path, M extends PathMethod<P>>({
  url,
  method,
  payload,
  params,
  options,
}: HttpHandlerProps<P, M>) => {
  const session = await getServerSession()
  const sessionToken = session?.token

  const contentType = determineContentType(options)
  const headers = generateRequestHeaders({ sessionToken, contentType })
  const resolvedUrl = resolveRequestUrl({ url, params })
  const resolvedOptions = resolveRequestOptions({ params, options })

  const body = await processRequestBody({
    url: resolvedUrl,
    method,
    payload,
    options,
  })

  return fetch(resolvedUrl, {
    next: {
      revalidate: (options?.revalidate ?? options?.cache) ? undefined : 60,
    },
    cache: options?.cache,
    method: method,
    body: body as BodyInit,
    headers: {
      ...headers,
      ...resolvedOptions?.headers,
    },
  })
}
